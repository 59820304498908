import React, { useState, useEffect } from 'react'
import { css } from '@emotion/css'
import { withTheme } from '@emotion/react'
import { FaGithub, FaStackOverflow } from 'react-icons/fa'
import logo from './assets/images/logo-alt2.png'

const Header = ({ theme }) => {
	const s = {
		header: css`
      position: fixed;
      top: 0;
      right: 0px;
      left: 0px;
  		display: flex;
  		align-items: center;
  		justify-content: space-between;
  		background-color: #0f1827;
  		z-index: 999;
  		padding: 25px 160px 0;
  		transition: padding 0.15s linear;
  		@media (max-width: 1200px) {
        padding-left: 30px;
        padding-right: 30px;
      }
    `,
    scrolled: css`
    	padding: 12px 160px 10px;
    	transition: padding 0.15s linear;
    	background-color: rgba(15, 24, 39, 0.75);
    	backdrop-filter: blur(10px);
    	box-shadow: ${theme.boxShadow.primary};
			@media (max-width: 1200px) {
	      padding-left: 40px;
	      padding-right: 40px;
	    }

    `,
    siteIcon: css`
    	color: white;
    	padding: 2px 5px 0;
    `,
	}

	const [windowYOffest, setWindowYOffest] = useState(0)

	useEffect(() => {
    const updateWindowYOffset = () => {
      const yOffset = window.pageYOffset 
      setWindowYOffest(yOffset)
    }

    window.addEventListener('scroll', updateWindowYOffset)
    return () => window.removeEventListener('scroll', updateWindowYOffset)
  }, [])

	return (
		<header>
			<div className={`${s.header} ${windowYOffest && s.scrolled}`}>
				<img src={logo} height={30} />
				<div>
					<a href="https://github.com/chris-poe" target="_blank">
						<FaGithub className={s.siteIcon} size={20} />
					</a>
					<a href="https://stackoverflow.com/users/4848700/chris-poe" target="_blank">
						<FaStackOverflow className={s.siteIcon} size={20} />
					</a>
				</div>
			</div>
		</header>
	)
}

export default withTheme(Header)
