import React, { useEffect } from 'react'
import { css } from '@emotion/css'
import enterView from 'enter-view'
import { withTheme } from '@emotion/react'
import ProjectBox from '../components/ProjectBox'
import ProjectBoxMobile from '../components/ProjectBoxMobile'

import {
  ArgoLanding,
  SnagASlipLanding,
  blackAndDeckerLogo,
  pier1Logo,
  sbaLogo,
  tivoLogo,
} from '../assets/images'

function Projects({ setWindowCount, theme }) {
  const s = {
    container: css`
      background-color: #483d8b;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px 40px 70px 0;
      @media (max-width: 1200px) {
        padding-right: 0;
        padding-bottom: 30px;
      }
    `,
    projectList: css`
      display: flex;
      flex-direction: column;
    `,
    logoContainer: css`
      max-width: 1010px;
      margin: 40px 0 0 40px;
      background-color: white;
      padding: 20px 20px 20px 40px;
      border-radius: 3px;
      display: flex;
      flex-direction: row;
      box-shadow: ${theme.boxShadow.primary};
      @media (max-width: 1200px) {
        margin-left: 0;
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width: 1200px) {
        display: none;
      }
    `,
    grid: css`
      display: flex;
      justify-content: flex-end;
      width: 100%;
      :last-of-type {
        justify-content: center;
      }
      @media (max-width: 1200px) {
        padding-top: 15px;
      }
    `,
    webPanel: css`
      @media (max-width: 1200px) {
        display: none;
      }
    `,
    mobilePanel: css`
      @media (min-width: 1200px) {
        display: none;
      }
    `,
  }

  useEffect(() => {
    enterView({
      selector: '#window-1',
      enter: () => setWindowCount(1),
      exit: () => setWindowCount(0),
      offset: 0.5,
    })
  }, [])

  return (
    <section id="window-1" className={s.container}>
      <div className={s.projectList}>
        <div className={s.webPanel}>
          <ProjectBox
            image={ArgoLanding}
            link="https://argonav.io/"
            title="Argo Navigation"
            description="A maritime navigation and social media mobile application that utilizes a 3D modeling algorithm to give real-time navigation and on-the-water alerts based on live updates from 50,000+ users."
            tech="React Native, Redux, Rails"
          />
          <ProjectBox
            image={SnagASlipLanding}
            link="https://www.snagaslip.com/"
            title="Snag-A-Slip"
            description="A marina slip rental web and mobile application that allows users to conveniently search, review, and make reservations with marinas and local dock owners."
            tech="React, React Native, Redux, Django"
            alt
          />
        </div>
        <div className={s.mobilePanel}>
          <ProjectBoxMobile
            image={ArgoLanding}
            link="https://argonav.io/"
            title="Argo Navigation"
            description="A maritime navigation and social media mobile application that utilizes a 3D modeling algorithm to give real-time navigation and on-the-water alerts based on live updates from 50,000+ users."
          />
          <ProjectBoxMobile
            image={SnagASlipLanding}
            link="https://www.snagaslip.com/"
            title="Snag-A-Slip"
            description="A marina slip rental web and mobile application that allows users to conveniently search, review, and make reservations with marinas and local dock owners."
          />
        </div>
        <div className={s.logoContainer}>
          <div className={s.grid}>
            <img src={blackAndDeckerLogo} height={50} />
          </div>
          <div className={s.grid}>
            <img src={pier1Logo} height={50} />
          </div>
          <div className={s.grid}>
            <img src={sbaLogo} height={50} />
          </div>
          <div className={s.grid}>
            <img src={tivoLogo} height={50} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default withTheme(Projects)
