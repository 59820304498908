import React, { useState, useEffect } from 'react'
import Header from './Header'
import { About, Projects } from './sections'
import PageLevel from './components/PageLevel'
import './App.css'

function App() {
  const [windowHeight, setCurrentWindowHeight] = useState(window.innerHeight)
  const [windowCount, setWindowCount] = useState(0)

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight
      setCurrentWindowHeight(newHeight)
    }

    window.addEventListener('resize', updateWindowDimensions)

    return () => window.removeEventListener('resize', updateWindowDimensions) 

  }, [])

  return (
    <div className="App">
      <Header />
      <PageLevel windowHeight={windowHeight} windowCount={windowCount} />
      <About windowHeight={windowHeight} setWindowCount={setWindowCount} />
      <Projects setWindowCount={setWindowCount} />
    </div>
  );
}

export default App
