import React from 'react'
import { css } from '@emotion/css'
import { withTheme } from '@emotion/react'
import Button from './Button'

function ProjectBox({ alt, image, link, title, description, tech, theme }) {
  const s = {
    container: css`
      display: flex;
      margin: 50px 0 50px 40px;
    `,
    panel1: css`
      width: 550px;
      height: 350px;
      background-image: url(${image});
      background-size: cover;
      border-radius: 3px;
      box-shadow: ${theme.boxShadow.primary};
    `,
    panel2: css`
      width: 460px;
      position: relative;
      text-align: right;
    `,
    description: css`
      background-color: #293447;
      width: 500px;
      position: absolute;
      top: 125px;
      left: -80px;
      color: white;
      padding: 20px;
      text-align: left;
      border-radius: 3px;
      box-shadow: ${theme.boxShadow.primary};
    `,
    altPanel2: css`
      text-align: left;
    `,
    altDescription: css`
      left: 0;
    `,
    heading: css`
      color: #08fdd8;
      padding-top: 4rem;
      margin: 0;
    `,
    title: css`
      color: white;
      padding-bottom: 8.5rem;
      margin: 0;
    `,
    tech: css`
      color: white;
      margin: 0;
    `,
    visitBtn: css`
      margin-top: 100px;
    `,
  }

  return (
    <div>
      {alt ? (
        <div className={s.container}>
          <a href={link} target="_blank" rel="noreferrer">
            <div className={s.panel1} />
          </a>
          <div className={s.panel2}>
            <h1 className={s.heading}>Featured Project</h1>
            <h2 className={s.title} style={{ fontWeight: 600 }}>
              {title}
            </h2>
            <h3 className={s.tech} style={{ fontWeight: 600 }}>
              {tech}
            </h3>
            <Button
              link={link}
              text="CHECK IT OUT"
            />
            <p className={s.description}>{description}</p>
          </div>
        </div>
      ) : (
        <div className={s.container}>
          <div className={`${s.panel2} ${s.altPanel2}`}>
            <h1 className={s.heading} style={{ textAlign: 'left' }}>
              Featured Project
            </h1>
            <h2
              className={s.title}
              style={{ fontWeight: 600, textAlign: 'left' }}
            >
              {title}
            </h2>
            <h3
              className={s.tech}
              style={{ fontWeight: 600, textAlign: 'left' }}
            >
              {tech}
            </h3>
            <Button
              link={link}
              text="CHECK IT OUT"
            />
            <p className={`${s.description} ${s.altDescription}`}>
              {description}
            </p>
          </div>
          <a href={link} target="_blank" rel="noreferrer">
            <div className={s.panel1} />
          </a>
        </div>
      )}
    </div>
  )
}

export default withTheme(ProjectBox)
