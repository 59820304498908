import React from 'react'
import { css } from '@emotion/css'
import { withTheme } from '@emotion/react'

function ProjectBoxMobile({ image, link, title, description, theme }) {
  const s = {
    container: css`
      margin: 50px 0 50px 40px;
      display: flex;
      justify-content: center;
      @media (max-width: 1200px) {
        margin: 0;
      }
      @media (max-width: 550px) {
        margin: 0 20px;
      }
    `,
    panel1: css`
      border-radius: 3px;
      box-shadow: ${theme.boxShadow.primary};
    `,
    img: css`
      max-width: 550px;
      max-height: 350px;
      width: 100%;
      // @media (max-width: 590px) {
      //   max-width: 550px;
      //   width: 100%;
      // }
    `,
    description: css`
      background-color: #293447;
      max-width: 510px;
      color: white;
      padding: 20px;
      text-align: left;
      border-radius: 3px;
      box-shadow: ${theme.boxShadow.primary};
      @media (min-width: 550px) {
        width: 100%;
      }
    `,
    heading: css`
      color: #08fdd8;
      padding-top: 4rem;
      margin: 0;
      text-align: left;
      @media (max-width: 1200px) {
        padding-top: 0;
        :last-of-type {
          padding-top: 1rem;
        }
      }
    `,
    title: css`
      color: white;
      margin: 0 0 1rem;
      font-weight: 600;
      text-align: left;
    `,
    visitBtn: css`
      margin-top: 100px;
    `,
  }

  return (
    <div className={s.container}>
      <div>
        <h1 className={s.heading}>
          Featured Project
        </h1>
        <h2 className={s.title}>
          {title}
        </h2>
        <a href={link} target="_blank" rel="noreferrer">
          <div className={s.panel1}>
            <img src={image} className={s.img} />
          </div>
        </a>
        <p className={`${s.description}`}>{description}</p>
      </div>
    </div>
  )
}

export default withTheme(ProjectBoxMobile)
