import React, { useRef } from 'react'
import { css, keyframes } from '@emotion/css'

function About({ windowHeight, setWindowCount }) {
  const typewriter = keyframes`
	  from {
      width: 0;
    }
	  to {
      width: 24em;
    }
	`
  const blinkTextCursor = keyframes`
	  from {
      border-right-color: rgba(255,255,255,1);
    }
	  to {
      border-right-color: transparent;
    }
	`
  const s = {
    container: css`
      background-color: #0f1827;
      height: calc(${windowHeight}px - 40px);
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 1200px) {
        height: ${windowHeight}px;
      }
      @media (min-width: 1200px) {
        padding: 0 80px;
      }
    `,
    content: css`
      max-width: 1100px;
      padding-left: 100px;
      @media (max-width: 768px) {
        padding-left: 30px;
      }
      @media (min-width: 1360px) {
        padding-left: 0;
      }
    `,
    p: css`
      color: white;
      font-size: 24px;
      padding-right: 60px;
      @media (max-width: 467px) {
        font-size: 18px;
        padding-right: 20px;
      }
    `,
    lineBase: css`
      color: white;
      position: relative;
      top: 50%;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      transform: translateY(-50%);
      text-align: left;
      width: 24rem;
      line-height: 60px;
      font-size: 60px;
      @media (max-width: 467px) {
        width: 12rem;
        line-height: 30px;
        font-size: 30px;
      }
      @media (min-width: 468px) and (max-width: 676px) {
        width: 18rem;
        line-height: 45px;
        font-size: 45px;
      }
    `,
    animLine1: css`
      animation: ${typewriter} 4s steps(44) 1s 1 normal both,
        ${blinkTextCursor} 1000ms steps(2) infinite normal;
      max-width: 517px;
      border-right: 2px solid rgba(255, 255, 255, 1);
      @media (max-width: 467px) {
        max-width: 260px;
      }
      @media (min-width: 468px) and (max-width: 676px) {
        max-width: 388px;
      }
    `,
    animLine2: css`
      animation: ${typewriter} 4s steps(44) 3.25s 1 normal both,
        ${blinkTextCursor} 1000ms steps(2) infinite normal;
      max-width: 539px;
      @media (max-width: 467px) {
        max-width: 270px;
      }
      @media (min-width: 468px) and (max-width: 676px) {
        max-width: 405px;
      }
    `,
    animLine3: css`
      animation: ${typewriter} 4s steps(44) 5.75s 1 normal both,
        ${blinkTextCursor} 1000ms steps(2) infinite normal;
      max-width: 400px;
      @media (max-width: 467px) {
        max-width: 201px;
      }
      @media (min-width: 468px) and (max-width: 676px) {
        max-width: 300px;
      }
    `,
    arrowDown: css`
      color: white;
      font-size: 20px;
    `,
  }

  const line1Ref = useRef(null)
  const line2Ref = useRef(null)
  const line3Ref = useRef(null)

  setTimeout(() => {
    if (line1Ref.current !== null) {
      line1Ref.current.style['border-right-width'] = '0'
    }
    if (line2Ref.current !== null) {
      line2Ref.current.style['border-right'] = '2px solid rgba(255,255,255,1)'
    }
  }, 3000)

  setTimeout(() => {
    if (line2Ref.current !== null) {
      line2Ref.current.style['border-right-width'] = '0'
    }
    if (line3Ref.current !== null) {
      line3Ref.current.style['border-right'] = '2px solid rgba(255,255,255,1)'
    }
  }, 5500)

  return (
    <section id="window-0">
      <div className={s.container}>
        <div className={s.content}>
          <h1 ref={line1Ref} className={`${s.lineBase} ${s.animLine1}`}>
            Hey there, I'm Chris.
          </h1>
          <h1 ref={line2Ref} className={`${s.lineBase} ${s.animLine2}`}>
            Front-End Developer.
          </h1>
          <h1 ref={line3Ref} className={`${s.lineBase} ${s.animLine3}`}>
            Problem solver.
          </h1>
          <p className={s.p}>
            Devoted front-end engineer with over five years of industry
            experience using React and React Native. Proven in collaborating,
            debugging and high-level problem solving. Proficient in minimizing
            tech debt by keeping code clean, scalable and reusable without
            hindering efficiency. Driven to go above and beyond to be an
            invaluable teammate and continuously improve products for users and
            future product owners.
          </p>
        </div>
      </div>
    </section>
  )
}

export default React.memo(About)
