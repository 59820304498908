import React from 'react'
import { GiCircle, GiPlainCircle } from 'react-icons/gi'
import { css } from '@emotion/css'

function PageLevel({
  windowHeight,
  windowCount,
}: {
  windowHeight: number,
  windowCount: number,
}) {
  const styles = {
    container: css`
      position: -webkit-sticky;
      position: sticky;
      top: calc((${windowHeight}px / 2) - 60px);
      width: 16px;
      @media (max-width: 1200px) {
        display: none;
      }
    `,
    wrapper: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 75px;
    `,
    indicator: css`
      margin: 2px 0;
      color: #08fdd8;
    `,
  }

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {windowCount === 0 ? (
          <GiPlainCircle className={styles.indicator} />
        ) : (
          <GiCircle className={styles.indicator} />
        )}
        {windowCount === 1 ? (
          <GiPlainCircle className={styles.indicator} />
        ) : (
          <GiCircle className={styles.indicator} />
        )}
        {/* {windowCount === 2 ? (
          <GiPlainCircle className={styles.indicator} />
        ) : (
          <GiCircle className={styles.indicator} />
        )} */}
      </div>
    </div>
  )
}

export default PageLevel
